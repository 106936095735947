import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import SvgCircleCheck from 'src/assets/svg/SvgCircleCheck.svg';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import SvgInfo from 'src/assets/svg/SvgInfo.svg';
import SvgWarning from 'src/assets/svg/SvgWarning.svg';
import '../../../../components/sniff-toast/index.scss';

const toastOpt = {
    position: 'bottom-right',
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
};

export const toasterInfo = (content = '') => toast.info(content, toastOpt);

export const toasterSuccess = ({ title, sub }) =>
    toast.success(
        <div className="review-toast">
            <span className="toast-icon">
                <SvgCircleCheck />
            </span>
            <div>
                <div className="snif-p snif-semibold mb-1">{title}</div>
                <div className="snif-p">{sub}</div>
            </div>
        </div>,
        toastOpt
    );

export const toasterDanger = ({ title, sub, addedOpt = {} }) =>
    toast.error(
        <div className="review-toast">
            <span className="toast-icon">{addedOpt.dark ? <SvgInfo /> : <SvgWarning />}</span>
            <div>
                <div className="snif-p snif-semibold mb-1">{title}</div>
                <div className="snif-p">{sub}</div>
            </div>
        </div>,
        { ...toastOpt, ...addedOpt }
    );

const CloseButton = ({ closeToast }) => <SvgCross2 onClick={closeToast} />;

const SniffToast = () => {
    return (
        <ToastContainer
            position="bottom-right"
            autoClose={2500}
            hideProgressBar={false}
            transition={Slide}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            closeButton={CloseButton}
            limit={3}
        />
    );
};

export default SniffToast;

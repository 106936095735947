import '../../../../../styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { AWS_STATIC_ASSET_HOST, STATSIG_API } from '../../../../consts/env.consts';
import SniffToast, { toasterDanger } from '../../utils/sniff-toast';
import CookieService from '../../../../services/cookie.service';
import { setFirstClick } from '../../../../helpers/firstClick';
import useMutateTrackEvent from '../../../../services/hooks/useMutateTrackEvent';
import useMutateViewHomeV3 from '../../../../services/hooks/useMutateViewHomeV3';
import { COOKIE_PARAM_NAME } from '../../../../consts/cookies.consts';
import useMobileScreen from '../../hooks/useMobileScreen';
import HomeSSR from '../../../../pages/home/home-ssr';
import '../../../../helpers/firebase';

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${AWS_STATIC_ASSET_HOST}/packs/`;

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
});

const Home = (props) => {
    const { client: statsigClient, isLoading: statsigLoading } = useClientAsyncInit(STATSIG_API, {
        customIDs: {
            uuid: CookieService.get(COOKIE_PARAM_NAME.SESSION_UUID),
        },
    });

    const isMobileSSR = useMobileScreen();

    useEffect(() => {
        setFirstClick();
    }, []);

    useEffect(() => {
        runStatsigAutoCapture(statsigClient);
    }, [statsigClient]);

    return (
        <ApolloProvider client={client}>
            <SniffToast />
            <StatsigProvider client={statsigClient}>
                <HomeSSR
                    {...{
                        ...props,
                        isMobileSSR,
                        useStateSSR: useState,
                        useEffectSSR: useEffect,
                        useRefSSR: useRef,
                        useMutateViewHomeV3,
                        useMutateTrackEvent,
                        toasterDanger,
                        statsigLoading,
                    }}
                />
            </StatsigProvider>
        </ApolloProvider>
    );
};

export default Home;
